import { T } from "@config/lang";
import fitr_en from "@presentation/assets/fitr-en.png";
import fitr_sq from "@presentation/assets/fitr-sq.png";
import fitr_mk from "@presentation/assets/fitr-mk.png";
import { useApp } from "@application/context/app-context";

const fitr = {
  en: fitr_en,
  sq: fitr_sq,
  mk: fitr_mk,
};

export function BeforeFooter() {
  const { language } = useApp();

  return (
    <div className="flex justify-center items-center my-4">
      <img className="h-16 mr-6" src={fitr[language]} alt="FITR" />
      <div className="text-lg">{T.fitr_supported}</div>
    </div>
  );
}
